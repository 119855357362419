<template>
    <h1> Create an Account </h1>
    <p> <ui-textfield type='text' placeholder="Display Name" v-model='name'/> </p>
    <p> <ui-textfield type='text' placeholder="Email" v-model='email'/> </p>
    <p> <ui-textfield input-type="password" type='password' placeholder="Password" v-model='password'/> </p>
    <div> <ui-button raised @click="register">
        Submit 
        </ui-button>
         <ui-spinner :active="submitting" size="small"></ui-spinner>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
    data(){
        return {
            email: ref(''),
            password: ref(''),
            name: ref(''),
            submitting: false,
        }
    },
    methods: {
        register(){
            this.submitting = true
            this.$store.dispatch('users/register', {email:this.email, password:this.password, name:this.name})
            .then(()=>{
                this.submitting = false
                this.$router.push('/activities')
            })
            
        }
    }
}
</script>

<style scoped>
.mdc-circular-progress {
    vertical-align: middle;
    margin: 0 8px
}
</style>